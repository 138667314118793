import { keyframes } from '@emotion/react';

// eslint-disable-next-line
const wordFader = keyframes`
    from {
        opacity: 0
    }
    to {
        opacity: 1
    }
`;

export const styleSheet = theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        height: '100%',
        minHeight: 0,
        backgroundColor: theme.colors.white02
    },
    columns: {
        display: 'flex',
        flex: 1,
        height: '100%',
        minHeight: 0,
        position: 'relative',
        zIndex: 1
    },
    contentWrapper: {
        backgroundColor: theme.colors.white01,
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        position: 'relative'
    },
    content: {
        display: 'flex',
        flex: '1',
        flexDirection: 'column',
        height: '100%',
        overflowY: 'auto',
        WebkitOverflowScrolling: 'touch',
        width: '100%',
        wordBreak: 'break-word'
    },
    notFound: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    },
    tabs: {
        flexShrink: 0,
        maxHeight: 40,
        boxShadow: `inset 0 -1px 0 0 ${theme.colors.gray01}`,
        paddingLeft: theme.margins.margin01,
        alignItems: 'flex-end',
        '.contentTab-selected': {
            color: theme.colors.black01
        },
        '.contentTab': {
            ':hover': {
                color: theme.colors.gray06
            },
            ':active': {
                color: theme.colors.black01
            }
        },
        [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
            maxWidth: '100vw'
        }
    }
});
